import {
	Box,
	Divider,
	Heading,
	Image,
	SimpleGrid,
	Stack,
	useColorModeValue,
} from '@chakra-ui/react';

interface Conference {
	_id: string;
	collegeFootballDataId: string;
	name: string;
	active: boolean;
	classification: string;
}

type ConferenceCardProps = {
	conference: Conference;
};

type ConferenceGridProps = {
	conferences: Conference[];
};

const PLACEHOLDER_IMAGE_URL =
	'https://www.honeywell.com/content/dam/placeholder-images/mosaic-1-1-placeholder.png';

const CONFERENCE_CLASSIFICATIONS_TO_LOAD_IMAGES = ['FBS', 'FCS'];

const ConferenceCard = ({ conference }: ConferenceCardProps) => {
	return (
		<Box
			role={'group'}
			p={6}
			pb={4}
			maxW={'250px'}
			w={'full'}
			bg={useColorModeValue('white', 'gray.800')}
			boxShadow={'2xl'}
			rounded={'lg'}
			pos={'relative'}
			zIndex={1}
		>
			<Image
				rounded={'lg'}
				height={200}
				width={200}
				objectFit={'cover'}
				src={
					CONFERENCE_CLASSIFICATIONS_TO_LOAD_IMAGES.includes(
						conference.classification
					)
						? `https://a.espncdn.com/i/teamlogos/ncaa_conf/500/${conference.collegeFootballDataId}.png`
						: PLACEHOLDER_IMAGE_URL
				}
				onError={({ currentTarget }) => {
					currentTarget.onerror = null; // prevents looping
					currentTarget.src = PLACEHOLDER_IMAGE_URL;
				}}
			/>
			<Divider pt={4} />
			<Stack pt={4} align={'center'}>
				<Heading fontSize={'2xl'} fontFamily={'body'} fontWeight={500}>
					{conference.name}
				</Heading>
			</Stack>
		</Box>
	);
};

const ConferenceGrid = ({ conferences }: ConferenceGridProps) => {
	return (
		<Box pl={12} pr={12} pt={4} pb={4}>
			<SimpleGrid
				spacing="40px"
				templateColumns="repeat(auto-fill, minmax(250px, 1fr))"
			>
				{conferences.map((conference) => {
					return (
						<ConferenceCard key={conference._id} conference={conference} />
					);
				})}
			</SimpleGrid>
		</Box>
	);
};

export default ConferenceGrid;
