import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Box, Divider, Heading } from '@chakra-ui/react';

import ConferenceGrid from '@/components/ConferenceGrid';

enum Classification {
	FBS = 'FBS', // Football Bowl Subdivision
	FCS = 'FCS', // Football Championship Subdivision
	DIVISION_TWO = 'DIVISION_TWO',
	DIVISION_THREE = 'DIVISION_THREE',
}
const CLASSIFICATION_SORT_ORDER = [
	Classification.FBS,
	Classification.FCS,
	Classification.DIVISION_TWO,
	Classification.DIVISION_THREE,
];
const CLASSIFICATION_DISPLAY_NAMES = {
	[Classification.FBS]: 'FBS (Football Bowl Subdivision - Division 1-A)',
	[Classification.FCS]:
		'FCS (Football Football Championship Subdivision - Division 1-AA)',
	[Classification.DIVISION_TWO]: ' Division Two',
	[Classification.DIVISION_THREE]: 'Division Three',
};

enum SubClassification {
	POWER_FIVE = 'POWER_FIVE',
	GROUP_OF_FIVE = 'GROUP_OF_FIVE',
	INACTIVE = 'INACTIVE',
}
const SUBCLASSIFICATION_SORT_ORDER = [
	SubClassification.POWER_FIVE,
	SubClassification.GROUP_OF_FIVE,
	null, // A null subclassification should come before anything inactive
	SubClassification.INACTIVE,
];
const SUBCLASSIFICATION_DISPLAY_NAMES = {
	[SubClassification.POWER_FIVE]: 'Power Five',
	[SubClassification.GROUP_OF_FIVE]: 'Group of Five',
	'': 'Other',
	[SubClassification.INACTIVE]: 'Inactive',
};

interface Conference {
	_id: string;
	collegeFootballDataId: string;
	name: string;
	active: boolean;
	classification: Classification;
}

interface ConferencesGroupedBySubClassification {
	subClassification: SubClassification | null;
	conferences: Conference[];
}

interface ConferencesGroupedByClassification {
	classification: Classification;
	conferences: ConferencesGroupedBySubClassification[];
}

interface GetConferencesByClassificationResponse {
	conferencesByClassification: ConferencesGroupedByClassification[];
}

const GET_CONFERENCES_BY_CLASSIFICATION = gql`
	{
		conferencesByClassification {
			classification
			conferences {
				subClassification
				conferences {
					_id
					collegeFootballDataId
					name
					active
					classification
				}
			}
		}
	}
`;

function compareClassifications(
	classificationA: Classification,
	classificationB: Classification
) {
	return (
		CLASSIFICATION_SORT_ORDER.indexOf(classificationA) -
		CLASSIFICATION_SORT_ORDER.indexOf(classificationB)
	);
}

function compareSubClassifications(
	subClassificationA: SubClassification | null = null,
	subClassificationB: SubClassification | null = null
) {
	return (
		SUBCLASSIFICATION_SORT_ORDER.indexOf(subClassificationA || null) -
		SUBCLASSIFICATION_SORT_ORDER.indexOf(subClassificationB || null)
	);
}

function sortGroupedConferences(
	groupedConferences: ConferencesGroupedByClassification[]
): ConferencesGroupedByClassification[] {
	let conferencesClone = Array.from(groupedConferences);

	conferencesClone.sort((a, b) => {
		return compareClassifications(a.classification, b.classification);
	});

	conferencesClone = conferencesClone.map((group) => {
		const groupConferencesClone = Array.from(group.conferences);

		groupConferencesClone.sort((a, b) => {
			return compareSubClassifications(
				a.subClassification || null,
				b.subClassification || null
			);
		});

		return {
			classification: group.classification,
			conferences: groupConferencesClone,
		};
	});

	return conferencesClone;
}

const DisplayConferencesByClassification = ({
	classification,
	conferences,
}: ConferencesGroupedByClassification) => {
	const numberOfVisibleSubClassifications = conferences.filter(
		({ subClassification }) => subClassification !== SubClassification.INACTIVE
	).length;
	const hasMultipleSubClassifications = numberOfVisibleSubClassifications > 1;
	return (
		<>
			<Heading as="h2" size="lg">
				{CLASSIFICATION_DISPLAY_NAMES[classification]}
			</Heading>
			{conferences.map(({ subClassification, conferences }, index) => (
				<React.Fragment key={`${classification}_${subClassification}`}>
					<DisplayConferencesBySubClassification
						hasMultipleSubClassifications={hasMultipleSubClassifications}
						subClassification={subClassification}
						conferences={conferences}
					/>
				</React.Fragment>
			))}
		</>
	);
};

interface DisplayConferencesBySubClassificationProps
	extends ConferencesGroupedBySubClassification {
	hasMultipleSubClassifications: boolean;
}

const DisplayConferencesBySubClassification = ({
	subClassification,
	conferences,
	hasMultipleSubClassifications = false,
}: DisplayConferencesBySubClassificationProps) => {
	// Hide all inactive conferences for now
	if (subClassification === SubClassification.INACTIVE) {
		return null;
	}

	return (
		<>
			{hasMultipleSubClassifications || subClassification !== null ? (
				<Heading as="h3" size="md" pt={4} pl={4}>
					{SUBCLASSIFICATION_DISPLAY_NAMES[subClassification || '']}
				</Heading>
			) : null}
			<ConferenceGrid conferences={conferences} />
		</>
	);
};

const DisplayConferences = () => {
	const {
		loading,
		error,
		data: { conferencesByClassification = [] } = {},
	} = useQuery<GetConferencesByClassificationResponse>(
		GET_CONFERENCES_BY_CLASSIFICATION
	);

	if (loading) return <p>Loading conferences..</p>;
	if (error) return <p>Error :(</p>;

	const sortedGroupedConferences = sortGroupedConferences(
		conferencesByClassification
	);

	return sortedGroupedConferences ? (
		<>
			{sortedGroupedConferences.map(
				({ classification, conferences }, index) => (
					<React.Fragment key={classification}>
						<DisplayConferencesByClassification
							classification={classification}
							conferences={conferences}
						/>
						{index + 1 !== sortedGroupedConferences.length && (
							<Divider orientation="horizontal" mt={12} mb={4} size="12px" />
						)}
					</React.Fragment>
				)
			)}
		</>
	) : (
		<p>No conference data</p>
	);
};

const ConferencePage = () => {
	return (
		<>
			<Heading as="h1" size="xl" mb="30px">
				NCAA Conferences
			</Heading>
			<Box pt={2} pb={2}>
				<DisplayConferences />
			</Box>
		</>
	);
};

export default ConferencePage;
