import { Route, Routes } from 'react-router';

import NotFound from '@/pages/404NotFound';
import Conferences from '@/pages/Conferences';

const Router = () => {
	return (
		<Routes>
			<Route path="/conferences" element={<Conferences />} />
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
};

export default Router;
