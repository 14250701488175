import { Box, Center, Text, useColorModeValue } from '@chakra-ui/react';

const currentYear = new Date().getFullYear();

const Footer = () => {
	return (
		<Box
			bg={useColorModeValue('white', 'gray.900')}
			color={useColorModeValue('gray.700', 'gray.200')}
		>
			<Center py={4}>
				<Text>© {currentYear} College Football. All rights reserved</Text>
			</Center>
		</Box>
	);
};

export default Footer;
