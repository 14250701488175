import { BrowserRouter } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';

import Footer from '@/components/Footer';
import Header from '@/components/Header';
import Router from '@/router';

export default function App() {
	return (
		<BrowserRouter>
			<Flex flexDirection={'column'} height={'100%'}>
				<Header />
				<Box flexGrow={1} overflowY={'auto'}>
					<Flex flexDirection={'column'} height={'100%'}>
						<Box
							flexShrink={1}
							flexGrow={1}
							flexBasis={'auto'}
							padding={'15px'}
						>
							<Router />
						</Box>
						<Footer />
					</Flex>
				</Box>
			</Flex>
		</BrowserRouter>
	);
}
